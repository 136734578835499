<template>
  <router-link
      class="pointer noUnderline"
      :class="[{'linkHover' : underline}]"
      v-if="!!to && !newWindow && !disabled"
      :to="to"
      :style="`color : ${color} !important`"
  >
    <slot></slot>
  </router-link>
  <a v-else-if="!!url && !disabled" :href="url" :target="!newWindow ? '_self' : '_blank'"
     :style="color ? `color : ${color} !important` : null"
     class="pointer noUnderline"
  >
    <slot></slot>
  </a>
  <span v-else>
     <slot></slot>
  </span>

</template>

<script>
export default {
  name: "wsLink",
  props : {
    to : {
      type : String
    },
    href : {
      type : String
    },
    newWindow : {
      type : Boolean,
      default : false
    },
    color : {
      type : String
    },
    disabled : {
      type : Boolean,
      default : false
    },
    underline : {
      type : Boolean,
    }
  },
  data() {
    return {
      url : ''
    }
  },
  watch : {
    to() {
      this.checkUrl()
    },
    href() {
      this.checkUrl()
    }
  },
  methods : {
    checkUrl() {
      this.url = this.href
      if ( this.to && this.newWindow ) {
        this.url = this.localeLink(this.to.substring(1) , true)
      }
    }
  },
  beforeMount() {
   this.checkUrl()
  }
}
</script>

<style scoped>

</style>